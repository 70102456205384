function Modal() {

}

Modal.prototype = {
    init(msg) {
       
        $('#app').append(`<div class="modal payment-popup date-message show" id="modal" tabindex="-1" role="dialog" aria-labelledby="team_manager-info" style="padding-right: 12px; display: block;" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <span class="close" data-dismiss="modal" aria-label="Close">
                  <img src="/img/icons/close.svg" alt="" class="img-fluid">
              </span>
              <div class="modal-container text-center">                
                  <h2>Message</h2>
                  <p>${msg}</p>
              </div>
              <a class="btn-message" data-dismiss="modal" href="javascript:void(0)">Okay</a>
          </div>
        </div>
      </div>`)

        $('#modal .close, #modal .btn-message').click(() => {
            this.close();
        })
    },
    close() {
        $('#modal').remove();
    }
}
Object.freeze(Modal);
export default Modal;