import steps from '../Data.js';
import Global from "../Global.js";

const Child = {

    create: function (uniqueID) {
        var typeQuery = window.location.href.split('=')[1];
        var typeQueryDecoded = decodeURIComponent(typeQuery);
        var type = typeQueryDecoded.replace(/[#&]/g, '');
        
        this.pushEmptyChildData(uniqueID);
        return `
        <div class="row form-set addChild" data-parent-id="${uniqueID}" id="addChild${uniqueID}">
            <div class="col-md-6 leftSide">
                <div class="form-heading">
                    <h1>About child #<span>${steps.totChild}</span></h1>
                    <a href="#" class="remove-appended"  data-parent-id="${uniqueID}" >
                        <img src='${Global.baseUrl}/img/icons/minus_sir.svg' alt="">
                    </a>
                </div>
                
                    
                <div class="form-group">
                    <label for="child_first_name">Child’s first name</label>
                    <input data-parent-id="${uniqueID}" data-name="childFirstName"  type="text" required name="childs_first_name[]" class="form-control childFirstName"  data-msg-required="Please, provide your child first name."  >
                </div>
                <div class="form-group">
                    <label for="child_last_name">Child’s last name</label>
                    <input data-parent-id="${uniqueID}" data-name="childLastName" type="text" required name="childs_last_name[]" class="form-control childLastName" data-msg-required="Please, provide your child last name." >
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Date of birth</label>
                        <input data-parent-id="${uniqueID}" data-name="dob" required type="text" data-msg-daterange="Date of birth must be between 01/01/2009-01/06/2017" readonly name="dob[]" class="form-control date-picker dob" data-msg-required="Please, provide your child's date of birth">
                    </div>
                    <div class="form-group col-md-6">
                    <label for="gender">Gender</label>
                    
                    
                    <select data-parent-id="${uniqueID}" data-name="gender" required class="custom-select gender" name="gender[]" id="gender" data-msg-required="Please, provide your child's gender">
                        <option selected value="">Please Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>                        
                    </select>
                    </div>
                </div>

                <div class="form-group">
                <label class="form-label">Select worldcup dates</label>
                <select data-parent-id="${uniqueID}" data-name="wcDates" required class="select2 form-control" name="wc_dates[]" data-msg-required="Please provide worldcup date"
                multiple="multiple">
                ${steps.allMiniWorldCups.map((item) =>
            (type == item.type) ? `<option value="${item.id}">${item.name}</option>` : ''

        )}
                </select>   
                </div>
            
            <!-- <div class="form-heading add-child-wrapper">
                <a href="javascript:void(0)" class="append_child" id="append_child${steps.totChild}">Add another child <img src="${Global.baseUrl}/img/icons/plus_sir.svg" alt=""> </a>
            </div> -->
        </div>
        <div class="col-md-6 rightSide">
            <div class="form-heading">
                <h1></h1>
            </div>
            <div class="form-group">
                <label for="parents_address">Parent/guardian's address <span class="same_address" data-name="address_map" data-parent-id="${uniqueID}" >Same as first child</span></label>
                <div class="maprow">
               
                </div>
            </div>
            <div class="form-group">
                <label for="medical_cond">Any current medical conditions or treatments.(asthma, etc..)</label>
                <textarea data-parent-id="${uniqueID}" name="medical_condition[]" class="form-control medical_condition" data-name="medical_condition" id="medical_cond"></textarea>
            </div>
           
            <div class="form-group">
                <label for="comments">Comment or request as to child's participation (e.g. Play with twin etc.)</label>
                <textarea class="form-control comments" name="comments[]"  data-name="comments"data-parent-id="${uniqueID}" > </textarea>
            </div>
        </div>
        </div>
    </div> `
    },

    pushEmptyChildData: function (uniqueID) {
        setTimeout(() => {
            steps.childs.push({
                id: uniqueID,
                childFirstName: '',
                childLastName: '',
                dob: '',
                gender: '',
                address_map: '',
                medical_condition: '',
                myTeams: [],
                comments: '',
                'away_mini_world_cup': [],
                'is_member': null,
                'current_club': '',
                'current_team': '',
                'current_other_club': '',
                'current_other_team': '',
                'playing_exp': '',
                clubs: [],
                hasQuota: false,
                quotaYear: false,
                medical_condition: '',
                comments: ''
            })

            $('.date-picker').datepicker({
                minDate: new Date(steps.dobRange.minDate.split('-').join(' ')),
                maxDate: new Date(steps.dobRange.maxDate.split('-').join(' ')),
                defaultDate: new Date(steps.dobRange.minDate.split('-').join(' ')),
                dateFormat: "dd-M-yy",
                changeMonth: true,
                changeYear: true,
            });


            if ($('#allMaps .inputGmap').length > 0) {
                var newMap = $('#allMaps .inputGmap')[0];

                $('#addChild' + uniqueID + ' .maprow').append(newMap);
            } else {
                $('#addChild' + uniqueID + ' .maprow').append(`
                <input 
                class="form-control controls parents_address address_map pac-target-input other" 
                name="address_map[]" 
                data-name="address_map" 
                data-parent-id="${uniqueID.toString()}" 
                type="text" id="ginput${uniqueID.toString()}"  
                autocomplete="off" 
               >
            `);
            }

            $('#addChild' + uniqueID + ' .maprow .pac-target-input').attr('data-parent-id', uniqueID.toString());

            $('.select2').select2();


            $('.select2').on('select2:selecting', function (e) {
                console.log('child2');
                var element = e.params.args.data.element;
                var $element = $(element);
                $element.detach();
                $(this).append($element);
            })


            $('.select2').on('select2:select', function (e) {

                id = e.params.data.id;

                $.ajax({
                    method: 'GET',
                    url: '/filter-worlcup-dates?id=' + id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    success: function (data) {
                        data.filter_wc_dates.forEach(element => {
                            $(".select2 option[value=" + element + "]")
                                .attr(
                                    'disabled',
                                    'disabled');
                        });

                        $(".select2").select2();

                        var currentUrl = window.location.href;

                        if (currentUrl.indexOf('?') != -1) {
                            currentUrl = currentUrl.split('?', 1)[0];
                            window.history.pushState({
                                path: currentUrl
                            }, '', currentUrl);
                        }

                        // Create a new URL
                        var newUrl = currentUrl + '?type=' + data
                            .type; // Modify the URL as needed

                        // Change the URL without refreshing the page
                        window.history.pushState({
                            path: newUrl
                        }, '', newUrl);
                    }
                });
            })



        },)

    }
}
Object.freeze(Child);
export default Child;