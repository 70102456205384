import steps from './Data.js'

const API = {
    getToken:function(){
        return $('meta[name="csrf-token"]').attr('content');
    },
    setMiniWorldCups : function(){
        return new Promise((resolve, reject) => {   
            $.ajax({
                type:'get',
                url:'/get-wc-dates' + window.location.search,
                success:function(data) {                   
                   let res = JSON.parse(data).map((item) => { 
                        return { id: item.id, name: item.date_title, slug: item.slug , type: item.type}
                    });          
                  resolve(res);
                }
             });
    
           }).then((response) => {
                return response;
            }).catch((error)=>{
                console.log(error);
            })
    },
    getRandomInt : function (min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        let uniqueInt =  Math.floor(Math.random() * (max - min + 1)) + min;
        let foundID = steps.childs.filter(item => item.id === uniqueInt).length>0;
        if(foundID){
            API.getRandomInt(0,9999)
        }else{
            return uniqueInt;
        }
    },
    fetchTariff : function (){
        setTimeout(()=>{
            steps.costTariff = {
                firstMember: 0,
                firstNonMember: 0,
                otherMember: 0
            };
        },2000)
    },
    fetchAllClubs : function (){
       
       return new Promise((resolve, reject) => {   
              
        $.ajax({
            type:'get',
            url:'/get-clubs',
            success:function(data) { 
               // Refine for All Clubs
               var allClubs = data["all_club"].map((item) => {
                            return {id :  item.id, title : item["club_title"], slug: item["club_title"] }
                            } )
               steps.enableClubOther ? allClubs.push( { id:0, title:'Other', slug:'other'}) : '';
               allClubs.unshift( { id:'', title:'Choose Club', slug:''});

               // Refine for Default Clubs
               var defaultClubs = data["default_list"].map((item) => {
                    return {id :  item.id, title : item["club_title"], slug: item["club_title"] }
               })
               defaultClubs.unshift( { id:'', title:'Choose Club', slug:''});

               var response = {status:true, data:{}}
               response.data.allClubs = allClubs;
               response.data.defaultClubs = defaultClubs;
               resolve(response);               
              

            }
         });

       }).then((response) => {
            return response;
        }).catch((error)=>{
            console.log(error);
        })
    },
     randTime : function(){
        return API.getRandomInt(1,2)*100;
    },
    
    getChildIndex:function(myid){
        var obj = steps.childs.find(item => item.id === parseInt(myid));
        return steps.childs.indexOf(obj);
    },

    getEachTeams : function (payload){
       return new Promise((resolve, reject) => {              
           $.ajax({
                      type:'post',
                      url:'/get-teams',
                      data:{"_token": API.getToken(),dob:payload.dob, gender:payload.gender},
                      error:function(error){
                        console.log(error);
                        return reject(error)
                      },
                      success:function(data) {
                        
                         return resolve(data); 
                      }
                   });
            
            }).catch((error)=>{
                console.log(error)
                return error;
            })            
            .then((response) => {
               
                var allData = [];
                response.forEach((item)=>{
                    item.unshift( { id:'', team_title:'Choose Team' });
                    allData.push(item);
                })
               
                return allData;
            })
    },

    getDateRange:function(payload){
        try{
            return new Promise((resolve, reject) => {              
                $.ajax({
                    type:'get',
                    url:'/get-date-range',
                    success:function(data) {                       
                       var mydata = JSON.parse(data);                      
                       var obj = { status : true, data: {minDate:mydata[0]["start_range"], maxDate : mydata[0]["end_range"] }}                      
                       resolve(obj);                       
                    }
                 });
               }).then((response) => {
                    return response;
                }).catch((error)=>{
                    console.log(error);
                })
        }catch(error){
            console.log(error);
        }
        
    },
    
    checkSeats : function(payload){
        try{
            
            return new Promise((resolve, reject) => {              
                $.ajax({
                    type:'POST',
                    url:'/seat-check',
                    data: {_token: API.getToken(), dob:payload.dob, gender:payload.gender , wcDates:payload.wcDates},
                    success:function(data) {                       
                      resolve(data);                       
                    }
                 });
               }).then((response) => {
                   
                    return response;
                }).catch((error)=>{
                    console.log(error);
                })           
        }catch(error){
            console.log(error)
        }
    },
    getPaymentDetail : function(payload){

        try{
            
            return new Promise((resolve, reject) => {              
                $.ajax({
                    type:'POST',
                    url:'/get-payment-details',
                    data: {_token: API.getToken(), details:payload.details},
                    success:function(data) {                       
                      resolve(data);                       
                    }
                 });
               }).then((response) => {
                    return response;
                }).catch((error)=>{
                    console.log(error);
                })   
        }catch(error){
            console.log(error)
        }
    },
    getMessages : function(payload){
        return fetch('/get-messages')
        .then(response => response.json())
        .catch(error =>  console.log(error))
    },
    getOtherOptions : function(payload){
        return fetch('/get-other-options')
        .then(response => response.json())
        .catch(error =>  console.log(error))
    }
   
  
}
Object.freeze(API);
export default API;