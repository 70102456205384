import Child from "../template/Child.js";
import steps from "../Data.js";
import API from "../API.js";
import Modal from '../template/Modal.js'
const modal = new Modal();
const stepChanged0 = {
    done: function () {
        $('.skip-opt').hide();
        $('.actions ul li:first-child').hide()
        this.loadAllEvents();
    },
    isDateValid: function (mydate) {
        if (steps.dobRange) {
            var minDate = new Date(steps.dobRange.minDate.split('-').join(' '));
            var maxDate = new Date(steps.dobRange.maxDate.split('-').join(' '));
            var currentDate = new Date(mydate.split('-').join(' '));

            return currentDate < minDate || currentDate > maxDate ? false : true;
        } else {
            alert("Date range is not fetched yet. Try it later")
        }

    },
    loadAllEvents: function () {

        $('#primary_phone_num, #primary_phone_num_confirm,#guardian_phone_num').mask('Z80 000 0000',
            {
                translation: {
                    'Z': {
                        pattern: /[0]/
                    }
                }
            });
        $('#phone_num').mask('000 000 0000',
            {
                translation: {
                    'Z': {
                        pattern: /[0]/
                    }
                }
            });


        $(document).on('input', '.comments', function () {
            var obj = steps.childs.find(item => item.id === parseInt($(this).attr('data-parent-id')));
            var objIndex = steps.childs.indexOf(obj);
            steps.childs[objIndex].comments = $(this).val();

        })

        //wcDates
        $(document).on('change', '.select2', function () {

            const values = $(this).val();
            var obj = steps.childs.find(item => item.id === parseInt($(this).attr('data-parent-id')));
            var objIndex = steps.childs.indexOf(obj);
            steps.childs[objIndex].wcDates = values
        })

        $(window).load(function () {
            if ($(window).width() < 992) {
                $('.nav_primary-wrapper .nav_primary ul.navbar-nav li a').on('click', function () {
                    $('.navbar-toggler').trigger('click');
                })
            }
            $('input#pac-input').wrap('<div class="map-input-wrapper"></div>');
            $('input#pac-input').attr('placeholder', '');

            //Disable Tab button
            $('#pac-input').keydown(function (objEvent) {
                if (objEvent.keyCode == 9) {  //tab pressed
                    objEvent.preventDefault(); // stops its action
                }
            })

        });

        var prevData = "";

        function capitalize(txt) {
            return txt.charAt(0).toUpperCase() + txt.slice(1)
        }
        var alphabetInput = 'input#ginput0,input#cardholder-name,input.childFirstName,input.childLastName,input#guardian_first_name,input#guardian_last_name,input#parent_name,input#parent_name_l';
        var paragraphInput = 'textarea#medical_cond,textarea.comments';
        var otherInput = 'input.customCurrentClub,input.customCurrentTeam';

        $(document).on('click', alphabetInput, function () {
            prevData = $(this).val();
            $(alphabetInput).on('input', function () {
                let patt = /^[\A-Za-z0-9\s']+$/i
                if (patt.test($(this).val())) {
                    prevData = capitalize($(this).val());
                    $(this).val(prevData);
                } else {

                    if ($(this).val().length > 0) {
                        $(this).val(prevData);
                        prevData = capitalize($(this).val());
                    } else {
                        prevData = capitalize($(this).val());
                    }
                }
            })
        }
        )

        $(document).on('click', paragraphInput, function () {
            prevData = $(this).val();
            $(paragraphInput).on('input', function () {
                let patt = /^[\A-Za-z0-9\s.,']+$/i
                if (patt.test($(this).val())) {
                    prevData = capitalize($(this).val());
                    $(this).val(prevData);
                } else {

                    if ($(this).val().length > 0) {
                        $(this).val(prevData);
                        prevData = capitalize($(this).val());
                    } else {
                        prevData = capitalize($(this).val());
                    }
                }
            })
        }
        )

        $(document).on('click', otherInput, function () {
            prevData = $(this).val();
            $(otherInput).on('input', function () {
                let patt = /^[\A-Za-z0-9\s-.']+$/i
                if (patt.test($(this).val())) {
                    prevData = capitalize($(this).val());
                    $(this).val(prevData);
                } else {

                    if ($(this).val().length > 0) {
                        $(this).val(prevData);
                        prevData = capitalize($(this).val());
                    } else {
                        prevData = capitalize($(this).val());
                    }
                }
            })
        }
        )



        $(document).on('click', '.append_child', function () {

            // Check validation for First Child
            var isValidFirstChild = true;
            // Check First Name, Last Name, DOB and gender.Udate isValidFirstChild value if first child's fields are filled
            $('.addChild input.childFirstName, .addChild input.childLastName, .addChild input.dob, .addChild select.gender').each(function () {

                if ($(this).val().trim() == 0) {

                    if ($(this).parent().find('label.error').length == 0) {
                        $(this).addClass('error');
                        $(this).parent().append('<label class="error">' + $(this).attr('data-msg-required') + '</label>')
                    }
                    isValidFirstChild = false;
                }
            })

            // If Quota is false
            var hasNoQuotaYear = steps.childs.filter(item => item.quotaYear === false).length > 0;
            if (hasNoQuotaYear) {
                isValidFirstChild = false;
            }


            if (isValidFirstChild) {
                steps.totChild = $('.addChild').length + 1;
                $(".append-wrapper").append(Child.create(API.getRandomInt(1, 9999)));
                $('.remove-appended').on('click', function (event) {
                    var ele = steps.childs.find(item => item.id === parseInt($(this).attr('data-parent-id')));

                    var eleIndex = steps.childs.indexOf(ele);

                    if (eleIndex >= 0) {
                        var uniqueID = parseInt($(this).attr('data-parent-id'));
                        var newMap = $('#addChild' + uniqueID + ' .maprow .inputGmap')[0];
                        $('#allMaps').append(newMap);

                        $(this).parents().eq(2).remove();

                        steps.totChild--;
                        $('.addChild .leftSide h1 span').each(function (index) {
                            $(this).html(index + 2)
                        })
                        steps.childs.splice(eleIndex, 1);
                    }

                })
            }




        });

        //});

        // Remove Child Dom
        var allRemoveEle = document.querySelectorAll('.remove-appended');



        $(document).on('change', '.addChild .date-picker,.addChild .gender,.addChild .select2', function (e) {

            try {
                // Check Pattern of Date
                // If Date Validation is false need to stop for API Call
                if ($(this).val() && !Array.isArray($(this).val()) && !stepChanged0.isDateValid($(this).val())) {

                    $(this).parent().parent().find('label.error').remove();
                    $(this).parent().append('<label class="error">' + $(this).attr('data-msg-daterange') + '</label>');
                    return false;
                } else {
                    $(this).parent().parent().find('label.error').remove();
                }

                // Check data of Gender and Date
                var eleID = $(this).attr('data-parent-id');
                parseInt(eleID);


                var dob = $('section .addChild[data-parent-id=' + eleID + '] .dob').val();

                var gender = $('section .addChild[data-parent-id=' + eleID + '] .gender').val();

                var wcDates = $('section .addChild[data-parent-id=' + eleID + '] .select2').val();

                console.log('dates', wcDates);

                /* Check Dob Pattern */
                var dobPatt = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

                if (dobPatt.test(dob) === false) {
                    $(this).parent().parent().find('label.error').remove();

                    return false;
                }
                /* Check Gender value */
                let genderOptions = ["male", "female", ""];
                if (genderOptions.includes(gender) === false) {
                    $(this).parent().parent().find('label.error').remove();

                    return false;
                }

                if (wcDates == null) {
                    let obj = steps.childs.find(item => item.id === parseInt(eleID));
                    let childIndex = steps.childs.indexOf(obj);
                    steps.childs[childIndex].hasQuota = false;
                }

                /* Dob and Gender sould not be empty */
                if (dob.length == 0 || gender.length == 0 || wcDates == null || wcDates.length == 0) {
                    return false;
                }

                /*
                Checking if Seat is available accordig to the dob and gender
                */
                API.checkSeats({ dob: dob, gender: gender, wcDates: wcDates }).catch(error => {

                }).then(response => {

                    var myparent = $('section .addChild[data-parent-id=' + eleID + ']');

                    let obj = steps.childs.find(item => item.id === parseInt(eleID));
                    let childIndex = steps.childs.indexOf(obj);
                    let yearonly = dob.slice(-4);
                    myparent.find('.rightSide .form-heading h1').html("Year of birth: <span class='year'>" + yearonly + "</span>");
                    if (!wcDates) {
                        steps.childs[childIndex].hasQuota = false;
                    }

                    if (response.status && response.queryQuota) {
                        let status = (response.status === "true");
                        let quotaYear = (response.queryQuota === "true");

                        console.log('before', steps.childs[childIndex]);

                        if (!status) {
                            if (quotaYear) {
                                myparent.find('.rightSide .form-heading h1').addClass('error');
                                if (steps.childs[childIndex].hasQuota != true) {
                                    steps.childs[childIndex].hasQuota = status;
                                } else if (steps.childs[childIndex].hasQuota == true && steps.childs[childIndex].wcDates.length == 1) {
                                    steps.childs[childIndex].hasQuota = status;
                                }
                                steps.childs[childIndex].quotaYear = quotaYear;
                            } else {
                                var option = $('section .addChild[data-parent-id=' + eleID + '] .select2 option[value=' + response.wcDate + ']');
                                option.attr('selected', false);
                                // steps.childs[childIndex].hasQuota = true;
                                // steps.childs[childIndex].quotaYear = true;
                                const filteredArr = steps.childs[childIndex].wcDates.filter((item) => item != response.wcDate);
                                steps.childs[childIndex].wcDates = filteredArr
                                myparent.find('.rightSide .form-heading h1').removeClass('error');
                            }

                            myparent.find('.rightSide .form-heading strong').remove();

                            if (!quotaYear || !status) {
                                response.msg.length > 0 ? modal.init(response.msg) : false
                            }
                        } else {
                            steps.childs[childIndex].hasQuota = status;
                            steps.childs[childIndex].quotaYear = quotaYear;
                            myparent.find('.rightSide .form-heading h1').removeClass('error');
                            modal.close();
                        }

                        // steps.childs[childIndex].hasQuota = status;
                        // steps.childs[childIndex].quotaYear = quotaYear;

                        console.log(steps.childs[childIndex]);
                    }
                })


            } catch (err) {
                console.log("Error :", err)
            }
        });


    }
}
Object.freeze(stepChanged0);
export default stepChanged0;