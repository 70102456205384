import steps from "../Data";

const stepChanging2 = {
    checkValidation: function() {

        var isFormValid = true;

        // Check Team Manager
        
        if(steps.isTeamManager && !steps.ownChild){
            $('section#steps-uid-0-p-2 #selectChild .error').remove();
            $('section#steps-uid-0-p-2 #selectChild').append('<label class="error">Child is required to select</label>')
            isFormValid = false;
        }

        // Check Referee if it is selected need to hav atlist one date
        // referees
        if($('.referees-wrapper #referees:checked').length>0 && steps.referees.dates.length == 0){
            $('.referees-wrapper .mini_world_cup-dates .refereesError').remove();
            $('.referees-wrapper .mini_world_cup-dates').append('<div class="error refereesError">Dates are required to choose if referees is selected</div>')
            isFormValid = false;
        }else{
            $('.referees-wrapper .mini_world_cup-dates .refereesError').remove();
        }
         // catering
        if($('.catering-sweet-wrapper #catering:checked').length>0 && steps.catering.dates.length == 0){
            $('.catering-sweet-wrapper .mini_world_cup-dates .cateringError').remove();
            $('.catering-sweet-wrapper .mini_world_cup-dates').append('<div class="error cateringError">Dates are required to choose if catering is selected</div>')
            isFormValid = false;
        }else{
            $('.catering-sweet-wrapper .mini_world_cup-dates .cateringError').remove();
        }

        // If Skip 3 then need to show next slide
        if(steps.skipStep3){
            return true;
        }else{
            steps.skipStep3 = false;
        }
        return isFormValid;
        
    }
}

Object.freeze(stepChanging2);
export default stepChanging2;