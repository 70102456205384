const GoogleMapAPI = {
    totalMap: 5,
    gmaps: [],
    gboxes: [],
    init() {
        var script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key='+map_api_key+'&libraries=places&callback=initMap';
        script.defer = true;
        script.async = true;
        script.id = "googlemap";

        $('#app').append('<div id="allMaps" style="display:none"></div>');

        let i = 0;
        while (i < this.totalMap) {
            $('#allMaps').append('<div id="inputGmap' + i + '" class="inputGmap"> <input class="form-control controls parents_address address_map" placeholder="" name="address_map[]" data-name="address_map" data-parent-id="0"  type="text" id="ginput' + i + '" /> <div style="display:block; height:150px" id="displayGmap' + i + '"> </div></div>');


            i++;
        }

        window.document.head.appendChild(script);


    },
    editMap(id) {
        console.log("Edit Map");
    },
    addMap(obj) {

        window.initMap = function() {
            let j = 0
            while (j < GoogleMapAPI.totalMap) {
                GoogleMapAPI.makeMap($('div#displayGmap' + j)[0], $('input#ginput' + j)[0])
                j++;
            }

        }

        setTimeout(() => {

            $('#addChild0 .maprow').append($('#inputGmap0'))
        }, 1000)

    },

    makeMap(mapBox, searchInputBox) {
        let map = new google.maps.Map(mapBox, {
            center: { lat: 53.3244427, lng: -6.3861289 },
            zoom: 7,
            mapTypeId: 'roadmap'
        });

        // Create the search box and link it to the UI element.
        var input = searchInputBox;

        var searchBox = new google.maps.places.SearchBox(input);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
        });

        var markers = [];
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach(function(marker) {
                marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            var bounds = new google.maps.LatLngBounds();
            places.forEach(function(place) {
                if (!place.geometry) {
                    return;
                }
                var icon = {
                    url: place.icon,
                    size: new google.maps.Size(71, 71),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(25, 25)
                };

                // Create a marker for each place.
                markers.push(new google.maps.Marker({
                    map: map,
                    icon: icon,
                    title: place.name,
                    position: place.geometry.location
                }));

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });

        //  gmaps.push(map);
        //  gboxes.push(searchBox);
    }





}

export default GoogleMapAPI;
Object.freeze(GoogleMapAPI)