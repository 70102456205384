import steps from '../Data.js';
import API from "../API.js";

const stepChanged1 = {
        init: function() {
            if(steps.hideStep2) {
                $('#app').append('<div class="loader"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i> <h3>Please wait while we are processing your request..</h3></div>')
            }
        },
        getChildIndex: function(that) {
            var obj = steps.childs.find(item => item.id === parseInt(that.attr('data-parent-id')));
            return steps.childs.indexOf(obj);
        },
        changeMiniWorldCupData: function(that) {
           
            var obj = steps.childs.find(item => item.id === parseInt(that.attr('data-parent-id')));

            var objIndex = steps.childs.indexOf(obj);
            var minworldcup = steps.childs[objIndex]['away_mini_world_cup'];
            
            if (that.prop("checked")) {
                let myOwnDataNo = minworldcup.filter(item=>item.slug === that.attr('data-day') ).length
                if(myOwnDataNo === 0){
                   minworldcup.push({name:that.val(), slug : that.attr('data-day')})                    
                }
            } else {
               if(minworldcup.filter(item => item.slug === that.attr('data-day')).length>0){
                    let obj = minworldcup.find(item => item.slug === that.attr('data-day'));
                    let objIndex = minworldcup.indexOf(obj);
                    minworldcup.splice(objIndex,1);                   
               }               
            }
           

            if (that.prop("checked")) {
            
                let myOwnDataNo = minworldcup.filter(item => item.slug === that.attr('data-day')).length               
                if (myOwnDataNo === 0) {
                    minworldcup.push({ name: that.val(), slug: that.attr('data-day') })
                   
                } else {
                    console.log("Something gona wrong on ", that.attr('data-day'));
                }

            } else {
              
                if (minworldcup.filter(item => item.slug === that.attr('data-day')).length > 0) {
                    let obj = minworldcup.find(item => item.slug === that.attr('data-day'));
                    let objIndex = minworldcup.indexOf(obj);
                    minworldcup.splice(objIndex, 1);
                   
                }

            }
          
            $('.childRow').each(function() {
                var unchecked = [];

                $(this).find('input.minicup').each(function() {
                    if (!$(this).prop("checked")) {
                        unchecked.push($(this).attr('data-day').split('_')[1]);
                    }
                })

                $(this).find('.canjoin').show();

                if (unchecked.length > 0) {
                    $(this).find('.canjoin').removeClass('d-none');
                    $(this).find('.canjoin .data').text(unchecked.map(item => { return ` ${item}` }));
                } else {
                    $(this).find('.canjoin').addClass('d-none');
                }

            })


        },
        changeIsMemberData: function(that) {
            let dataIndex = stepChanged1.getChildIndex(that);

            if (that.val() == "yes") {
                steps.childs[dataIndex]["is_member"] = true
                $('#steps-uid-0-p-1 .childRow:nth-child(' + parseInt(dataIndex + 1) + ') .currentClubBlock').html('')
                $('#steps-uid-0-p-1 .childRow:nth-child(' + parseInt(dataIndex + 1) + ') .currentClubBlock').append(this.getDefaultClubs({ parentID: that.attr('data-parent-id') }))

            } else {
                steps.childs[dataIndex]["is_member"] = false;
                $('#steps-uid-0-p-1 .childRow:nth-child(' + parseInt(dataIndex + 1) + ') .currentClubBlock').html('')
                $('#steps-uid-0-p-1 .childRow:nth-child(' + parseInt(dataIndex + 1) + ') .currentClubBlock').append(
                        `<select class="currentClub" data-parent-id="${steps.childs[dataIndex].id}" data-name="current_club" name="current_club[]">
                    ${steps.allClubs.map(item =>  `<option value="${item.id}">${item.title}</option>` ).join('')}
                </select><div class="customCurrentClubBlock d-none">
                <label>Please enter your club name (e.g. Cabinteely)</label><input type="text" class="customCurrentClub " name="current_other_club[]" data-name="current_club" value="" data-parent-id="${that.attr('data-parent-id')}" /></div>`)
            }       
        },
    done : function(){
        $('.skip-opt').show();
        steps.skipStep2 = false;
        $('.skip-opt').on('click', function(e) {
            e.preventDefault();
            steps.skipStep2 = true;
            $(".actions li a[href='#next']").trigger('click');
        })
        // $('.skip-opt').hide();
        $('.actions ul li:first-child').show();
        $('.childRow').remove();
        
        var allFirstName = document.querySelectorAll('input.childFirstName');
        var childRowWrapper = document.querySelector('.childRowWrapper');                     
        
        var i = 0;
        while (i < steps.childs.length) {
            var data = {
                index: i,
                name: allFirstName[i].value,                                         
                parentID: steps.childs[i].id,
                "playing_exp" : steps.childs[i]["playing_exp"]
            }
            var ele = document.createElement('div');
            ele.innerHTML = stepChanged1.create(data);
            childRowWrapper.appendChild(ele.firstChild);
            $(document).on('click', '.append_child', function() {
                // Check validation for First Child
                var isValidFirstChild = true;
                // Check First Name, Last Name, DOB and gender.Udate isValidFirstChild value if first child's fields are filled
                $('.addChild input.childFirstName, .addChild input.childLastName, .addChild input.dob, .addChild select.gender').each(function(){                
                    if($(this).val().trim() == 0){
                        if ($(this).parent().find('label.error').length==0) {
                            $(this).addClass('error');
                            $(this).parent().append('<label class="error">'+$(this).attr('data-msg-required')+'</label>')
                        }                           
                        isValidFirstChild = false;                      
                    }
                })
                
                // If Quota is false
                var hasNoQuotaYear = steps.childs.filter(item => item.quotaYear === false).length>0;
                if(hasNoQuotaYear){
                    isValidFirstChild = false;  
                }                
    
               if(isValidFirstChild){
                    steps.totChild=$('.addChild').length+1;
                    $(".append-wrapper").append(Child.create(API.getRandomInt(1,9999)));
                    Child.pushEmptyChildData();
               }
           });
            i++;
        }
        if(steps.hideStep2) {
            $( ".skip-opt" ).trigger( "click" );
        }
    },
    
    create: function (data) {
       
        stepChanged1.setEventsStep2();
        
        return `<div class="row childRow" data-parent-id="${data.parentID}">
            <div class="col-md-12">
                <h3>Child name: <span>${data.name}</span></h3>
            </div>
            <div class="col-md-6">
                <div class="team-inner-wrapper away_for_mwc">
                    <p>Tick below if you're unavailable for any of the Mini World Cup?</p>
                    <div class="form-row mini_world_cup-dates">
                    
                        ${
                            steps.allMiniWorldCups.length > 0 ?                                      
                            steps.allMiniWorldCups.map(item=>{
                            return ` <div class="form-group col-lg-4 col-md-6 col-sm-4 col-6">
                            <label class="checkbox-wrapper date-checkbox"> ${item.name}
                                    ${  
                                        steps.childs[data.index]['away_mini_world_cup'].filter(obj => obj.name === item.name ).length>0 
                                        ?  `<input class="minicup" checked type="checkbox" value="${item.name}" data-day="${item.slug}" name="away_${item.slug}[]" data-name="${item.name}" data-parent-id="${data.parentID}" />`
                                        :  `<input class="minicup" type="checkbox" value="${item.name}" data-day="${item.slug}" name="away_${item.slug}[]" data-name="${item.name}" data-parent-id="${data.parentID}" />`                         
                                    }
                                
                                <span class="checkmark"></span>
                            </label>
                        </div>`
                            }).join('')
                                                                
                            : `<strong>Sorry no any Cup is defined</strong>`                                    
                        }
    
                        <!-- Mini World Cup Remain -->
                       
                        <p class="note canjoin "> ${data.name} is available on days : <span class="data">
                        ${
                            steps.allMiniWorldCups.length > 0 ?                                      
                            steps.allMiniWorldCups.map((item,index)=>{
                                return  (
                                        steps.childs[data.index]['away_mini_world_cup'].filter(obj => obj.name === item.name ).length>0 
                                        ?  `<span class="empty"></span>`
                                        :  `<span> ${item.slug.split("_")[1]}<i>,</i> </span>`
                                    )                       
                                
                            }).join('')
                            :''
                        }
                         </span></p>
                    </div>
                </div>
                <div class="team-inner-wrapper cabinteely-member">
                    <p>${club_name} member? </p>
                    <div class="form-row mini_world_cup-dates">
                        <div class="form-group col-lg-3 col-md-4 col-sm-3 col-4">
                            <label class="checkbox-wrapper date-checkbox">Yes
                            ${ (steps.childs[data.index]["is_member"] == true || steps.childs[data.index]["is_member"] == undefined)
                                ? `<input data-name="isMember" checked data-parent-id="${data.parentID}"  type="radio" class="memberyes" name="cabinteely_member${data.index}[]" value="yes">` 
                                : `<input  data-name="isMember"  data-parent-id="${data.parentID}"  type="radio" class="memberyes" name="cabinteely_member${data.index}[]" value="yes"></input>` }
                                
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="form-group col-lg-3 col-md-4 col-sm-3 col-4">
                            <label class="checkbox-wrapper date-checkbox">No
                                ${steps.childs[data.index]["is_member"] == false  
                                ? `<input  data-name="isMember" data-parent-id="${data.parentID}"  type="radio" checked   class="memberno" name="cabinteely_member${data.index}[]" value="no">` 
                                : `<input  data-name="isMember" data-parent-id="${data.parentID}"  type="radio" class="memberno" name="cabinteely_member${data.index}[]" value="no"></input>` }
                            
                                
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="child-team-append team-inner-wrapper">
                    <div class="club-block-wrapper">
                        <!-- <h1>About Child #1</h1> -->
                        <div class="form-group">
                            <label for="current_club">Current club*</label>
                            <div class="currentClubBlock">
                            ${ 
                                stepChanged1.setUpCurrentClub(data.parentID)
                                ? stepChanged1.setUpCurrentClub(data.parentID)
                                : `<span class="error">Current Club is not available</span>`
                            }
                            ${
                                this.setUpCurrentOtherClub(data.parentID)
                            }
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="current_team">Current team*  </label>
                            <div class="currentTeamBlock">
                            
                            ${
                                stepChanged1.setUpCurrentTeam(data.parentID)
                                ? stepChanged1.setUpCurrentTeam(data.parentID)
                                : `<span class="error">Current Team is not available</span>`
                            }
                            
                            ${
                                this.setUpCurrentOtherTeam(data.parentID)
                            }
                            
                            </div>
                        </div>
                        <div class="form-group">
                            <p class="note">* If you reflect “no current club” above and your child is subsequently found to be a registered member of any league registered club then your child will be disqualified from any further participation in the MWC.</p>
                        </div>
                        <div class="form-group">
                            <label for="experience">Playing experience (0-10 years)* </label>
                            <input data-name="playing_exp" data-parent-id="${data.parentID}" type="text" min="0" max="10" value="${data["playing_exp"] ? data["playing_exp"] : '' }" minlength="1" maxlength="2" class="form-control playingExp" name="experience[]" id="experience${data.index}" />
                        </div>
                    </div>
                </div>
            </div>
            </div>`
    },
    setUpCurrentOtherClub : function(parentID){
        let dataIndex = stepChanged1.getChildIndexByID(parentID);
        let val = steps.childs[dataIndex]["current_other_club"];
        let myclass = steps.childs[dataIndex]["current_club"] === "0" ? '' : 'd-none'
        return `<div class="${myclass} customCurrentClubBlock"><label>Please enter your club name (e.g. Cabinteely)</label><input type="text" class="customCurrentClub " name="current_other_club[]" data-name="current_club" value="${val}" data-parent-id="${parentID}" /></div>`
    },
    setUpCurrentOtherTeam : function(parentID){
        let dataIndex = stepChanged1.getChildIndexByID(parentID);
        let val = steps.childs[dataIndex]["current_other_team"];
        let myclass = steps.childs[dataIndex]["current_team"] === "0" ? '' : 'd-none';
        return `<div class="${myclass} customCurrentTeamBlock"><label>Please enter your team name (e.g. U-11A)</label><input type="text" class="customCurrentTeam" name="current_other_team[]" data-name="current_team" value="${val}" data-parent-id="${parentID}" /></div>`
    },
    setEventsStep2 : function(){
        
        $(document).on('change','.currentTeam',function(){            
            stepChanged1.changeCurrentTeam($(this))              
        })
        
        $(document).on('input','input.playingExp',function(){          
            var numval = parseInt($(this).val());
            if(isNaN(numval)){
               $(this).val("");
            }else{
               $(this).val(numval);
               stepChanged1.changePlayingExp($(this))
            }                          
        })

        $(document).on('input','.customCurrentTeam',function(){
            let objIndex = stepChanged1.getChildIndex($(this));            
            steps.childs[objIndex]["current_other_team"] = $(this).val();
           
            
        })
        $(document).on('change','select.currentClub',function(){   
            
             let currentClubVal = $(this).val();
           
             if(currentClubVal === "0"){                
                 $(this).parent().find('.customCurrentClubBlock').removeClass('d-none');               
             }else if(currentClubVal === "-1"){
                $(this).parent().find('.customCurrentClubBlock').addClass('d-none');
                $(this).parent().parent().parent().find('.currentTeamBlock label.error').remove();
             }else{
                $(this).parent().parent().parent().find('.customCurrentClubBlock').addClass('d-none');
                 steps.childs[stepChanged1.getChildIndex($(this))]["current_club"]=currentClubVal;
                 
             }
             steps.childs[stepChanged1.getChildIndex($(this))]["current_club"]=currentClubVal;
           
         })
         $(document).on('input','input.customCurrentClub',function(){
             
             let objIndex = stepChanged1.getChildIndex($(this));            
             steps.childs[objIndex]["current_other_club"] = $(this).val();  

         })
         
        $(document).on('change','input.minicup',function(){
            stepChanged1.changeMiniWorldCupData($(this));
        })
        $(document).on('change','input.memberyes,input.memberno',function(){
           
            let dataIndex = stepChanged1.getChildIndex($(this));
           
            stepChanged1.changeIsMemberData($(this))
            if($(this).val()=="yes"){
                steps.childs[dataIndex]["current_club"] = steps.defaultClubs[0].id;
            }else{
                steps.childs[dataIndex]["current_club"] = "";
            }
           
            
        })
        
        
    },
    changeCurrentClub :function(){
        
    },
    changePlayingExp : function(that){  
            
        let objIndex = stepChanged1.getChildIndex(that);
        steps.childs[objIndex]["playing_exp"] = that.val();       
    },
    changeCurrentTeam : function(that){
       
        let objIndex = this.getChildIndex(that);       
        steps.childs[objIndex]["current_team"] = that.val();
        if(that.val() === "0"){          
            that.parent().find('.customCurrentTeamBlock').removeClass('d-none');
           // that.parent().append(`<input type="text" value="" class="customCurrentTeam" style="margin-top: 5px" name="current_other_team[]" data-name="current_name" data-parent-id="${that.attr('data-parent-id')}" />`)
        }else{
            // Make sure Trigger is Select Box
            that.parent().find('.customCurrentTeamBlock').addClass('d-none');
        } 
    },
    setUpCurrentTeam:function(id){
        
        let objIndex = stepChanged1.getChildIndexByID(id); 
        let myteam = steps.childs[objIndex].myTeams;
       
        stepChanged1.fetchEachCurrentTeam(id);
       
       
    },
    getChildIndexByID:function(myid){
        var obj = steps.childs.find(item => item.id === parseInt(myid));
        return steps.childs.indexOf(obj);
    },
    /*
    isMember : function(){
        return steps.childs[this.getChildIndexByID(this.parentID)]["is_member"]
    },
    */
    fetchEachCurrentTeam:  function(id){
        var objIndex = this.getChildIndexByID(id);  
        var obj = {dob : steps.childs[objIndex].dob, gender : steps.childs[objIndex].gender };      

       
        API.getEachTeams(obj).catch(error => {
            console.log(error)
         }).catch(error => {
             console.log(error);
         })
         .then((response) => {
            if(response){
               
                steps.childs[objIndex].myTeams=response[0].map((item)=>{
                    return {id:item.id, title:item["team_title"], slug : item["team_title"]}
                })
                steps.enableTeamOther ? steps.childs[objIndex].myTeams.push({id:0,title:'Other',slug:'other',id:0}) : '';
       
                let myteam = steps.childs[objIndex].myTeams;
                           
                $('.childRow:nth-child('+parseInt(objIndex+1)+') .currentTeamBlock').prepend(`
                    <select class="currentTeam" data-parent-id="${id}" data-name="current_team" name="current_team[]">
                            ${myteam.map(item =>  `<option ${item.id.toString() === steps.childs[objIndex]["current_team"] ? 'selected' : ''} value="${item.id}">${item.title}</option>` ).join('')}</option>
                    </select>`)
                    $('.childRow:nth-child('+parseInt(objIndex+1)+') .currentTeamBlock span').remove();
                
                
            }else{
                console.log("Server could not retrive team data of selected user.")
            }            

         })     

    },
    getAllClubListOnly:function(obj){
        
        var dataIndex = this.getChildIndexByID(obj.parentID);
      
        parseInt(dataIndex);
        
        return steps.allClubs.length >0 ? 
                `<select class="currentClub" data-parent-id="${steps.childs[dataIndex].id}" data-name="current_club" name="current_club[]">
                        ${ 
                          steps.allClubs.map(option => `<option value="${option.id}" ${option.id === parseInt(steps.childs[dataIndex]["current_club"]) ? 'selected' : ''}>${option.title}</option>`).join('')
                        }
                   
                        
                </select> 
                `
        : `Sorry no any clubs are found`
    },

    getDefaultClubs : function(obj){
        let objIndex=null;
        objIndex = obj.parentID>=0 ? this.getChildIndexByID(parseInt(obj.parentID)) : null;
        parseInt(objIndex);
        let firstDefaultClubID = steps.defaultClubs[1].id;
        parseInt(firstDefaultClubID);
        return  `<select class="currentClub" data-name="current_club" name="current_club[]" data-parent-id="${obj.parentID>=0 ? obj.parentID : ''}">
                    ${ 
                        steps.childs[objIndex]["current_club"] === "" 
                        ? steps.defaultClubs.map(option => `<option value="${option.id}" data-test="${steps.childs[objIndex]["current_club"]}" ${ option.id === firstDefaultClubID ? 'selected' : ''}>${option.title} </option>`).join('')
                        : steps.defaultClubs.map(option => `<option value="${option.id}" data-test="${steps.childs[objIndex]["current_club"]}" ${ parseInt(steps.childs[objIndex]["current_club"]) === option.id ? 'selected' : ''}>${option.title} </option>`).join('')
                    }
                </select> 
                `
    },
    getAllClubList:function(obj){       
        var dataIndex = this.getChildIndexByID(obj.parentID);       
        let clubname = steps.childs[dataIndex]["current_club"];
        parseInt(dataIndex);
        return steps.allClubs.length >0 ? 
                `<select class="currentClub" data-parent-id="${steps.childs[dataIndex].id}" name="current_team[]" data-name="current_team">
                    ${ steps.allClubs.map(option => `<option value="${option.id}" ${option.id === steps.childs[dataIndex]["current_club"] ? 'selected' : ''}>${option.title}</option>`).join('')}
                </select> 
                `
        : `Sorry no any clubs are found`
    },
    isOurClub : function(clubname){
        return steps.allClubs.filter(item => item.id === clubname ).length === 0 ? false : true
    },
    isMyTeams : function(obj){
        let dataIndex = this.getChildIndexByID(obj.id);
        return steps.childs[dataIndex].myTeams.filter(item => item.id === obj["current_team"] ).length === 0 ? false : true
    },
    setUpCurrentClub:function(parentID){
        
        let dataIndex = this.getChildIndexByID(parentID);
        let currentClubDom;
        if(steps.childs[dataIndex].is_member){
            currentClubDom = this.getDefaultClubs({parentID:parentID});
        }else{
            currentClubDom = this.getAllClubListOnly({parentID:parentID});
            

        }
        
        return currentClubDom;
    },
    getPaymentDetail : function(){

    }
 
}
Object.freeze(stepChanged1);
export default stepChanged1;