import steps from './Data.js';
import API from './API.js';
import stepChanged0 from "./stepChanged/step0.js";
import stepChanged1 from "./stepChanged/step1.js";
import stepChanged2 from "./stepChanged/step2.js";
import stepChanged3 from "./stepChanged/step3.js";
import stepChanged4 from "./stepChanged/step4.js";

import stepChanging0 from "./stepChanging/step0.js";
import stepChanging1 from "./stepChanging/step1.js";
import stepChanging2 from "./stepChanging/step2.js";
import GoogleMapAPI from "./GoogleMapAPI.js";

(function() {

    $(document).ready(function() {



        /* Call API */

        //API.fetchAllClubs();

        API.setMiniWorldCups().catch(error => {
            console.log(error)
        }).then(response => {

            steps.allMiniWorldCups = response;
        })

        // Call Fetch All Clubs
        API.fetchAllClubs().catch(error => {
            console.log(error)
        }).then(response => {
            steps.allClubs = response.data.allClubs;
            steps.defaultClubs = response.data.defaultClubs;
        })


        // Call Date Range API
        API.getDateRange().catch(error => {
            console.log(error)
        }).then(response => {
            steps.dobRange = response.data;
            $(function() {
                $('.date-picker').datepicker({                    
                    minDate: new Date(steps.dobRange.minDate.split('-').join(' ')),
                    maxDate: new Date(steps.dobRange.maxDate.split('-').join(' ')),
                    defaultDate: new Date(steps.dobRange.minDate.split('-').join(' ')),
                    dateFormat: "dd-M-yy",
                    changeMonth: true,
                    changeYear: true,
                });


            });
        })

        // Get Other Options 
        API.getOtherOptions().catch(error => {
            console.log(error)
        }).then(response => {
           try{
            steps.enableClubOther = response["enable_club_others"] === "true";
            steps.enableTeamOther = response["enable_team_others"] === "true";           
           }catch(err){
               console.log("Error on getting Other Options value : ", err);
           }            
        })

        // Get all available messages
        API.getMessages()
        .then(data => steps.messages = data )
        .catch(error => console.log(error))

       

        // Google Map
        setTimeout(() => {
            GoogleMapAPI.init();
            var obj = {
                id: 0,
                mapBox: $('.addChild[data-parent-id=0] .map')[0],
                searchBox: $('.addChild[data-parent-id=0] .address_map')[0]
            }
            GoogleMapAPI.addMap(obj);
        }, 2000);

        $(document).on('click', '.same_address', function(){
            var id = $(this).attr('data-parent-id');
            GoogleMapAPI.editMap(1)
            $('input.address_map[data-parent-id='+id+']').val($('input#ginput0').val());
            $('input.address_map[data-parent-id='+id+']').focus();
            $('input.address_map[data-parent-id='+id+']').trigger('change');
            $('#displayGmap1').trigger('click')
            var ele = steps.childs.find(item=>item.id === parseInt($(this).attr('data-parent-id')));
    
            var eleIndex=steps.childs.indexOf(ele);
            steps.childs[eleIndex][$(this).attr('data-name')]=steps.childs[0][$(this).attr('data-name')];
           
        })
       

        var form = $('#contact');


      
        form.children("div").steps({
            headerTag: "div.step-heading",
            bodyTag: "section",
            labels: {
                current: "current step:",
                pagination: "Pagination",
                finish: "NEXT STEP",
                next: "NEXT STEP",
                previous: "BACK",
                loading: "Loading ..."
            },
            transitionEffect: "fade",
            onInit: function () {
                if(steps.hideStep2 && steps.hideStep3) {
                    form.children("div").steps("remove",1);
                    form.children("div").steps("remove",1);
                }
            },
            onStepChanged: function(event, currentIndex, next) {
                switch (currentIndex) {
                    case 0:
                        stepChanged0.done();
                        break;
                    case 1:
                        if(steps.hideStep2 && steps.hideStep3) {
                            stepChanged3.done();
                        } else {
                            stepChanged1.init();
                            stepChanged1.done();
                        }
                        break;
                    case 2:
                        stepChanged2.done();
                        break;
                    case 3:
                        stepChanged3.done();
                        break;
                    case 4:
                        stepChanged4.done();
                        break;
                }

            },
            onStepChanging: function(event, currentIndex, newIndex) {

                if (currentIndex > newIndex) {
                    return true;
                } else {

                    switch (currentIndex) {
                        case 0:
                            return stepChanging0.checkValidation();                       
                        case 1:
                            return stepChanging1.checkValidation();
                        case 2:
                            return stepChanging2.checkValidation();                   

                    }

                    return form.valid();
                }

            },
            onFinishing: function(event, currentIndex) {

            },
            onFinished: function(event, currentIndex) {
                $('.thanks-wrapper').show();
                $('.actions').hide();
                $('.steps ul li').addClass('disabled');
            }
        });

        function _goToStep(wizard, options, state, index)
        {
            return paginationClick(wizard, options, state, index);
        }
    
        $.fn.steps.setStep = function (step)
        {
    
            var options = getOptions(this),
                state = getState(this);
    
            return _goToStep(this, options, state, step);
    
        };

        $('.actions ul li:first-child').hide();
        
        // Load all Events of Step 0
        stepChanged0.loadAllEvents();
        $(document).on('change', '.parents_address,.medical_condition', function() {
            setTimeout(() => {
                changeDataofChilds($(this));
            }, 1500)
        });

        $(document).on('change', 'input#phone_num', function() {
            changeParentsData($(this));
        });
        /* ******************************** Step 2 Event Handling ******************************** */

        /* Change Parent's Data */
        function changeParentsData(that) {
            steps.info[that.attr('data-name')] = that.val();

        }
  
        /* Change each child data */
        function changeDataofChilds(that) {
            var ele = steps.childs.find(item => item.id == parseInt(that.attr('data-parent-id')));
            var eleIndex = steps.childs.indexOf(ele);
            steps.childs[eleIndex][that.attr('data-name')] = that.val();

        }

        function childsDataCondition(that) {
            if (
                that.attr('data-name') == "childFirstName" || that.attr('data-name') == "childLastName" ||
                that.attr('data-name') == "gender" || that.attr('data-name') == "dob" ||
                that.attr('data-name') == "parents_address" ||
                that.attr('data-name') == "medical_condition") {

                changeDataofChilds(that)
            }

        }

        function parentDataCondition(that) {
            if (
                that.attr('data-name') == "guardian_first_name" || that.attr('data-name') == "guardian_last_name" ||
                that.attr('data-name') == "guardians_phone" || that.attr('data-name') == "primary_phone_num_confirm" ||
                that.attr('data-name') == "home_number" || that.attr('data-name') == "email_address") {

                changeParentsData(that)
            }
        }

        function instantValidate() {

            $(document).on('change', 'select[required],input[required]', function() {

                childsDataCondition($(this));
                parentDataCondition($(this));

                if ($(this).val() && $(this).val().length > 0) {
                    // If error design need to remove
                    if ($(this).parent().find('label.error').length > 0) {
                        $(this).removeClass('error');
                        $(this).parent().find('label.error').remove();
                    }
                } else {
                    // If not found error design need to show error
                    if ($(this).parent().find('label.error').length == 0) {
                        $(this).addClass('error');
                        $(this).parent().append('<label class="error">' + $(this).attr('data-msg-required') + '</label>')
                    }
                }
            })


        }

        instantValidate();


        // Toggle Optional Info
        $('.optional-billing-info p').on('click', function() {
            $(this).parent().find('.additonal_info').slideToggle(500);
        });

        $('.actions ul li a[href="#finish"]').on('click', function() {
            //alert('step 4');
            $('.btn-checkout').trigger('click');
        })

        $(window).on('resize', function() {
            if ($(window).width() < 992) {
                $('.nav_primary-wrapper .nav_primary ul.navbar-nav li a').on('click', function() {
                    $('.navbar-toggler').trigger('click');
                })
            }
        });





    })
})();
$(window).bind("load", function() {

    // your javascript event
    setTimeout(() => {


        $('input.pac-target-input').wrap('<div class="map-input-wrapper"></div>');
        $('input.pac-target-input').attr('placeholder', '');
        $('input.pac-target-input').keydown(function(objEvent) {
            if (objEvent.keyCode == 9) { //tab pressed
                objEvent.preventDefault(); // stops its action
            }
        })
    }, 1000);
    /// call your function here
    //Disable Tab button
});