import API from "../API";

const stepChanged4 = {
        done: function() {
            // Payment Detail
           
            $('#app').append('<div class="loader"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i> <h3>Loading ...</h3></div>')
        }
    }
        Object.freeze(stepChanged4);
        export default stepChanged4;