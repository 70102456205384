import steps from "../Data";

const stepChanged2 = {
        done: function() {
            // console.log("stepsss",steps)
            if(steps.hideStep3) {
                $('#app').append('<div class="loader"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i> <h3>Please wait while we are processing your request..</h3></div>')
            } else {
                $('.loader').remove();
            }
            $('.skip-opt').show();
            steps.skipStep3 = false;
            $('.skip-opt').on('click', function(e) {
                e.preventDefault();
                steps.skipStep3 = true;
                $(".actions li a[href='#next']").trigger('click');
                
            })

            function showChilds() {
                var str = "";
                $('.childFirstName').each(function(index) {

                    if (index == 0) {
                        str += '<option selected value="' + $(this).val() + '" >' + $(this).val() + '</option>';
                    } else {
                        str += '<option value="' + $(this).val() + '" >' + $(this).val() + '</option>';
                    }

                })
                return str;
            }
            $('#selectChild select').remove();

            $('#selectChild').append('<select name="team_manager_childs_name" class="form-control"><option value="">Select Child</option>' + showChilds() + '<option value="other">Other team</option></select>')

            stepChanged2.setGuardianData();
            stepChanged2.setTeamManager();
            stepChanged2.setIsSponsership();
            stepChanged2.setReferee();
            stepChanged2.setCatering();
            if(steps.hideStep3) {
                $( ".skip-opt" ).trigger( "click" );
            }
        },

        setGuardianData: function() {

            // Initialize Data
            steps.guardiansInfo.fname = !steps.guardiansInfo.fname ? steps.info["guardian_first_name"] : steps.guardiansInfo.fname;
            steps.guardiansInfo.lname = !steps.guardiansInfo.lname ? steps.info["guardian_last_name"] : steps.guardiansInfo.lname;
            steps.guardiansInfo.phone = !steps.guardiansInfo.phone ? steps.info["guardians_phone"] : steps.guardiansInfo.phone;

            // Events for form input
            $('#parent_name').val(steps.guardiansInfo.fname);
            $('#parent_name_l').val(steps.guardiansInfo.lname);
            $('#guardian_phone_num').val(steps.guardiansInfo.phone);
            $('#parent_name, #parent_name_l, #guardian_phone_num').on('keyup', function() {
                steps.guardiansInfo[$(this).attr('data-name')] = $(this).val()

            })


        },
        setTeamManager: function() {
            // Initialize Data
            if (steps.isTeamManager) {
                $('input#team_manager').prop("checked", true);
                $('.team-manager-details').addClass('active');
                $('#selectChild select').val(steps.ownChild);
            } else {
                $('input#team_manager').prop("checked", false);
                $('.team-manager-details').removeClass('active');
                $('#selectChild select').val('');
            }

            // Events for form input
            $('input#team_manager').on("change", function() {
                steps.isTeamManager = $(this).prop("checked");

                if ($(this).prop("checked")) {
                    $('.team-manager-details').addClass('active');

                } else {
                    $('.team-manager-details').removeClass('active');
                    $('#selectChild select').val('');
                    steps.ownChild = '';
                }
            })

            $(document).on('change', '#selectChild select', function() {
                steps.ownChild = $(this).val();

            })

        },
        setIsSponsership: function() {
            // Initialize Data
            steps.isSponsorship ? $('input#prizes').prop("checked", true) : $('input#prizes').prop("checked", false)

            // Events
            $('input#prizes').on('change', function() {
                steps.isSponsorship = $(this).prop("checked");

            })


        },

        setCatering: function() {
                // Initialize Referees Checkbox  
                steps.catering.status ? $('input#catering').prop("checked", true) : $('input#catering').prop("checked", false);

                // Initilize HTML according to the data
                if (steps.allMiniWorldCups.length > 0) {
                    steps.catering.status ? $('.catering-sweet-wrapper .cateringBlock').addClass('active') : $('.catering-sweet-wrapper .cateringBlock').removeClass('active')
                    $('.catering-sweet-wrapper .cateringBlock').html(`
               <h4>Catering and sweet shop details required</h4>
               <p>Please detail your availability below</p>
               <div class="form-row mini_world_cup-dates">
               ${steps.allMiniWorldCups.map( item => `
               <div class="form-group col-lg-4 col-md-6 col-sm-4 col-6">
                   <label class="checkbox-wrapper date-checkbox">${item.name}
                       <input name="${item.slug}_cat" value="${item.name}" type="checkbox" ${steps.catering.dates.find(obj =>  obj === item.name ) ? `checked` : ``}>
                       <span class="checkmark"></span>
                   </label>
               </div>
           ` ).join('')}
               </div>
               
           `);
       }

       // Events
       $(document).on('change','.catering-sweet-wrapper .cateringBlock input[type=checkbox]',function(){
           if($(this).prop("checked")){
               steps.catering.dates.push($(this).val());
           }else{
               let obj = steps.catering.dates.find(item => item === $(this).val());
               let objIndex = steps.catering.dates.indexOf(obj);
               steps.catering.dates.splice(objIndex,1);
           }
           
       })  

       $('section#steps-uid-0-p-2  input#catering').on('change',function(){  
            steps.catering.status=$(this).prop("checked");
            if($(this).prop("checked")){
                
                $('.catering-sweet-wrapper .cateringBlock').removeClass('active');
                $('.catering-sweet-wrapper .cateringBlock').addClass('active');
            }else{
                $('.catering-sweet-wrapper .cateringBlock').removeClass('active');
                steps.catering.dates=[];
                $('section#steps-uid-0-p-2 .catering-sweet-wrapper .cateringBlock input[type=checkbox]').prop("checked",false);
                
            }
        }) 

    },
    setReferee : function(){

        // Initialize Referees Checkbox  
       steps.referees.status ? $('input#referees').prop("checked",true) : $('input#referees').prop("checked",false);

        // Initilize HTML according to the data
        if(steps.allMiniWorldCups.length>0){
            // Activating or Deactive Wrapper
            steps.referees.status ? $('.referees-wrapper .cateringBlock').addClass('active') : $('.referees-wrapper .cateringBlock').removeClass('active')
            
            $('.referees-wrapper .cateringBlock').html(`
                <h4>Referee details required</h4>
                <p>Please detail your availability below</p>
                <div class="form-row mini_world_cup-dates">
                ${steps.allMiniWorldCups.map( item => `
                <div class="form-group col-lg-4 col-md-6 col-sm-4 col-6">
                    <label class="checkbox-wrapper date-checkbox">${item.name}
                        <input name="${item.slug}" value="${item.name}" type="checkbox" ${steps.referees.dates.find(obj =>  obj === item.name ) ? `checked` : ``}>
                        <span class="checkmark"></span>
                    </label>
                </div>
            ` ).join('')}
                </div>
                
            `);
        }

        // Events
        $(document).on('change','.referees-wrapper .cateringBlock input[type=checkbox]',function(){
            if($(this).prop("checked")){
                steps.referees.dates.push($(this).val());
            }else{
                let obj = steps.referees.dates.find(item => item === $(this).val());
                let objIndex = steps.referees.dates.indexOf(obj);
                steps.referees.dates.splice(objIndex,1);
            }
            
        })

        $('section#steps-uid-0-p-2  input#referees').on('change',function(){  
            steps.referees.status=$(this).prop("checked");
            if($(this).prop("checked")){
                $('.referees-wrapper .cateringBlock').removeClass('active');
                $('.referees-wrapper .cateringBlock').addClass('active');
            }else{
                $('.referees-wrapper .cateringBlock').removeClass('active');
                steps.referees.dates=[];
                $('section#steps-uid-0-p-2 .referees-wrapper .cateringBlock input[type=checkbox]').prop("checked",false);
                
            }
        })
    }
}
Object.freeze(stepChanged2);
export default stepChanged2;