
import steps from "../Data";
import API from "../API";
const stepChanged3 = {
    done: function () {
        if ($('.loader')) {
            $('.loader').remove();
        }
        $('.skip-opt').hide();
        $('.actions ul li:first-child').show();

        // Change Text of Next Step

        if ($(".actions a[href='#finish']").length > 0) {
            $(".actions a[href='#finish']").text('MAKE PAYMENT')
        }

        // For Card Holder Name need to be only alphabets possible with space if require
        var prevVal = ""
        $('#cardholder-name').on('input', function () {
            let patt = /^[A-Z ]+$/i;
            if (patt.test($(this).val())) {
                prevVal = $(this).val();
            } else {
                if ($(this).val().length > 0) {
                    $(this).val(prevVal);
                } else {
                    prevVal = ""
                }
            }
        })
        // IF any of child is member need tot active member section
        if ($('.memberyes:checked').length > 0) {
            $('#subscribe_fc-member').prop("checked", true);
        } else {
            $('#subscribe_non-member').prop("checked", true);
        }
        function showWaitingMessage(msg) {

            $('.total-cost-wrapper .waitingMessage').html(msg);


        }
        // Specify Total Childe Value
        $('.total-cost-wrapper h2 span').text(steps.childs.length);
        let childsData = steps.childs.map((item) => {
            return {
                childFirstName: item.childFirstName,
                childLastName: item.childLastName,
                dob: item.dob,
                gender: item.gender,
                quotaYear: item.quotaYear,
                wcDates: item.wcDates,
                hasQuota: item.hasQuota,
                "away_mini_world_cup": item["away_mini_world_cup"].map(item => item.name),
                ["is_member"]: steps.hideStep2 ? true : item["is_member"]
            }
        })
        /* Storing in Session for temporary */
        sessionStorage.clear();
        sessionStorage.setItem("childsData", JSON.stringify(childsData));

        /* If has quota need to display payment box and need to hit api for payment otherwise need to show overloay box */
        let hasQuota = steps.childs.filter(item => item.hasQuota === true).length > 0;

        sessionStorage.setItem("hasQuota", hasQuota);


        if (hasQuota) {

            if (document.querySelectorAll('.overlayBox').length > 0) {
                document.querySelector('.overlayBox').classList.add('d-none');
            }

            API.getPaymentDetail({ details: childsData }).catch(error => {
                console.log(error)
            }).then(response => {
                $('.children p').each(function (item) { $(this).remove() });
                $('.children span').each(function (item) { $(this).remove() });
                $('.children br').each(function (item) { $(this).remove() });

                $('.waiting-children p').each(function (item) { $(this).remove() });
                $('.waiting-children span').each(function (item) { $(this).remove() });
                $('.waiting-children br').each(function (item) { $(this).remove() });

                var waitingChildren = false;

                var isFree = response.total_price == 0 ? 'true' : 'false';
                sessionStorage.setItem("isFree", isFree);

                if (isFree == 'true')
                    $('#contact div.actions ul li:last-child a').trigger('click');

                if (response.childs_data) {
                    response.childs_data.map(function (child) {
                        // Check if there is at least one true value in child.worldcup_dates
                        var hasTrueValue = Object.values(child.worldcup_dates).includes('true');

                        if (hasTrueValue) {
                            $('.children').append(`<p class="mb-0">${child.child} :</p>`);

                            $.each(child.worldcup_dates, function (wc_title, wc_date) {
                                if (wc_date == 'true') {
                                    $('.children').append(`<span>${wc_title} : Available</span><br/>`);
                                }
                            });
                            $('.children').append('<br>');
                            waitingChildren = false;
                        }

                        var hasFalseValue = Object.values(child.worldcup_dates).includes('false');

                        $('.waiting-count').addClass('d-none');
                        if (hasFalseValue) {
                            waitingChildren = true;
                            $('.waiting-count').removeClass('d-none');
                            $('.waiting-children').append(`<p class="mb-0">${child.child} :</p>`);

                            $.each(child.worldcup_dates, function (wc_title, wc_date) {
                                if (wc_date == 'false') {
                                    $('.waiting-children').append(`<span>${wc_title} : Unavailable</span><br/>`);
                                }
                            });
                            $('.waiting-children').append('<br>');
                        }
                    })
                }

                $('.total-cost-wrapper h2 span').text(response.total_children);
                $('.member-cost span').text(response.cabinteely_members_price);
                $('.nonmember-count .totNonMember_count span').text(response.non_members);
                $('.nonmember-count .non-member-cost span').text(response.non_members_price);
                $('.total-cost-wrapper h3 span').text(response.total_price);
                // Waiting List Number

                response.waiting_list && response.waiting_list > 0
                    ? $('section#steps-uid-0-p-3 p.watingList span').text(response.waiting_list)
                    : $('section#steps-uid-0-p-3 p.watingList span').text('0');

                // Waiting List Messagae
                if (waitingChildren) {
                    response.waiting_message && response.waiting_message.length > 0
                        ? showWaitingMessage(response.waiting_message)
                        : showWaitingMessage('')
                } else {
                    showWaitingMessage('')
                }

                document.querySelector('.paymentBox').classList.remove('d-none');

            }).catch(error => {
                console.log("Error :", error)
            })
        } else {

            $('#contact div.actions ul li:last-child a').trigger('click');



        }

    }
}

Object.freeze(stepChanged3);
export default stepChanged3;