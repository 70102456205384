
import steps from "../Data.js";
const stepChanging0 = {
    checkValidation : function(){
        /* ****************************************************************
        VALIDATION CHECK : requied filed on step 1 
        **************************************************************** */
       
        var isValidForm = true;
        var requiredValidation = true;
        $('section#steps-uid-0-p-0 input[required],section#steps-uid-0-p-0 select[required]').each(function(){                
            if(Array.isArray($(this).val()) == false && $(this).val().trim().length == 0){   
                    setTimeout(()=>{
                        $(this).parent().find('label.error').remove();
                        $(this).addClass('error');
                        $(this).parent().append('<label class="error">'+$(this).attr('data-msg-required')+'</label>')
                    },500)
                   
                    isValidForm = false;  
                    requiredValidation = false; 
            }
        })  
                   
        
        /* ****************************************************************
            VALIDATION CHECK : Init Digit . Initially 08 need to be in tel no 
            for primary phone number 
            **************************************************************** */
        var phonePatternValidation = true;
        function validateInitDigit(ele){
                if(!/^(08)([0-9]){1} [0-9]{3} [0-9]{4}$/.test(ele.val()) ){  
                    ele.parent().find('label.error').remove();
                    ele.parent().append('<label class="error">'+ele.attr('data-msg-init')+'</label>');
                    isValidForm = false;  
                    phonePatternValidation = false; 
                }else{
                    ele.parent().find('label.error').remove();
                    return true
                }
        }
        
        if(validateInitDigit($('#primary_phone_num')) == false){
           isValidForm = false;   
            
        }

        if(validateInitDigit($('#primary_phone_num_confirm')) == false){
            isValidForm = false;   
        }
        //  Home Phone Number 
      
        if($('#phone_num').val().length>0){
            let patt = /^(?=.*[0-9])[- +()0-9]+$/   
          if(!patt.test($('#phone_num').val())){
              
              $('#phone_num').parent().find('label.error').remove();
              $('#phone_num').parent().append('<label class="error">'+$('#phone_num').attr('data-msg-pattern') +'</label>');
             isValidForm = false;   
          }
        }

       /* ****************************************************************
            VALIDATION CHECK : matched . Check primary phone number need to 
            match with confirm phone number 
            **************************************************************** */
        var phoneMatchValidation = true;
        if($('input#primary_phone_num_confirm').val() != $('input#primary_phone_num').val()){
            $('input#primary_phone_num_confirm').parent().find('label.error').remove();
            $('input#primary_phone_num_confirm').parent().append('<label class="error">'+$('input#primary_phone_num_confirm').attr('data-msg-equal-to')+'</label>');
            isValidForm = false;   
            phoneMatchValidation =false;        
        }
        
        /* ****************************************************************
            VALIDATION CHECK : date range . DOB need to be check 
            match with confirm phone number 
            **************************************************************** */
        var dateRangeValidation = true;
        function isDateRangeValid(mydate){
            
            let minDate = new Date(steps.dobRange.minDate.split('-').join(' '));
            let maxDate = new Date(steps.dobRange.maxDate.split('-').join(' '));

            let myDateInit = new Date(mydate.split('-').join(' '));
            let minDateInit = minDate;
            let maxDateInit = maxDate;

            return myDateInit < minDateInit || myDateInit > maxDateInit ? false : true ;
        }
        
        $('input.dob').each(function(){
            /* Date Patten Check */
            var dobPatt = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
            
            if(dobPatt.test($(this).val()) === false){
                
                $(this).parent().parent().find('label.error').remove();                     
                isValidForm = false;  
                dateRangeValidation = false;
            }else if(!isDateRangeValid($(this).val())){

                let minDate = new Date(steps.dobRange.minDate.split('-').join(' '));
                let maxDate = new Date(steps.dobRange.maxDate.split('-').join(' '));

                let modifyMinDate = minDate.getDate()+"/"+(1+minDate.getMonth())+"/"+minDate.getFullYear();
                let modifyMaxDate = maxDate.getDate()+"/"+(1+maxDate.getMonth())+"/"+maxDate.getFullYear();
                
                $(this).parent().parent().find('label.error').remove();
                $(this).parent().parent().append(`<label class="error"> Date of birth must be between ${modifyMinDate} - ${modifyMaxDate} </label>`);        
               
                isValidForm = false;  
                dateRangeValidation = false;
                
            }else{
                $(this).parent().parent().find('label.error').remove();
            }
        })

        /* ****************************************************************
            VALIDATION CHECK : if user does not have year quota need to stop procced 
        **************************************************************** */
       var hasNoQuotaYear = steps.childs.filter(item => item.quotaYear === false).length>0;
       if(hasNoQuotaYear){
         isValidForm = false;  
       }
      
       /* ****************************************************************
            VALIDATION CHECK : if email is invalid format
        **************************************************************** */
      
       if(!$('input#email')[0].checkValidity()){
            $('input#email').parent().find('label.error').remove();
            $('input#email').parent().append('<label class="error"> Email address is invalid.</label>'); 
            isValidForm = false;  
       }else{
          $('input#email').parent().find('label.error').remove();
       }

        /* ****************************************************************
            VALIDATION CHECK : if parents_address is invalid format
        **************************************************************** */
      
       $('section#steps-uid-0-p-0 input.parents_address').each(function(){                
            if($(this).val().trim().length == 0){   
                    setTimeout(()=>{
                        $(this).parent().find('label.error').remove();
                        $(this).addClass('error');
                        $(this).parent().parent().parent().addClass('errorBlock');
                        $(this).parent().append('<label class="error"> Address is required to fill </label>')
                    },500)
                
                    isValidForm = false;  
                    requiredValidation = false; 
            }else{
                $(this).parent().find('label.error').remove();
                $(this).removeClass('error');
                $(this).parent().parent().parent().removeClass('errorBlock');
            }
        })  
       return isValidForm;
       
    }
}
Object.freeze(stepChanging0);
export default stepChanging0;