const stepChanging1 = {
    init: function () {

    },
    checkValidation: function() {

        var isTemSelectionValid = true;
        // Required Field must be filled
        $('.childRow input[required],.row select[required]').each(function() {
            if (Array.isArray($(this).val()) == false && $(this).val().trim().length == 0) {
                
                setTimeout(() => {
                    $(this).parent().find('label.error').remove();
                    $(this).addClass('error');
                    $(this).parent().append('<label class="error">' + $(this).attr('data-msg-required') + '</label>')
                }, 1000)

                isTemSelectionValid = false;

            }
        })

        // Either Member Yes or No need to be click
        $('.childRow').each(function(index) {
            if ($(this).find('input.memberyes:checked,input.memberno:checked').length == 0) {
                $(this).find('input.memberyes').each(function(){ this.checked = true; });
                isTemSelectionValid = true;
            }
        })

        // Validation for Playing Experience must be with the range of 0-15
        $('.childRow input.playingExp').each(function(index) {
            let exp = $(this).val();
            
            if (exp < 11 && exp>=0) {                
                $(this).parent().find('lable.error').remove();
                return true;
            } else {                
                setTimeout(()=>{
                    $(this).parent().find('lable.error').remove();
                },500)
               
                $(this).parent().append('<label class="error">Experience should be numerical and must be 0-10 . </label>');
                isTemSelectionValid = false;
            }
        })

        return isTemSelectionValid;

    }
}
Object.freeze(stepChanging1);
export default stepChanging1;