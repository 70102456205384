const steps = {
    childs:[{
        id:0,
        childFirstName:'',
        childLastName:'',
        dob:'',
        gender:'male',
        comments:'',
        'address_map':'',
        'medical_condition':'',
        'away_mini_world_cup':[],
        'is_member': (enable_step_2 == 0) ? true : false,
        'current_club':['10'],
        'current_team':'',
        'playing_exp':'0',
        'current_other_club' :'',
        'current_other_team' :'',
        myTeams:[],
        hasQuota:false,
        medical_condition:'',
        comments:'',
        wcDates:[]

    }],
                     
    info:{
        'guardian_first_name':'',
        'guardian_last_name':'',
        'guardians_phone':'',
        'primary_phone_num_confirm':'',
        'home_number':'',
        'email_address':''                   
    },
    guardiansInfo:{
        fname :null,
        lname:null,
        phone:null,
    },           
   allMiniWorldCups:[],
   allClubs:[],
   costTariff : {},
   totChild:1,
   officialClub:{title: club_name, slug:'cabinteely_club'},
   defaultClubs:[],
   dobRange:null,
   isTeamManager : false,
   ownChild : null,
   enableClubOther : false,
   enableTeamOther : false,
   isSponsorship:false,
   referees:{
       status : false,
       dates :[]
   },
   catering:{
    status : false,
    dates :[]
   },
   skipStep2 : true,
   skipStep3 : true,
   hideStep2 : (enable_step_2 == 0) ? true : false,
   hideStep3 : (enable_step_3 == 0) ? true : false,
   messages:{},
   wcDates:[]


}
Object.seal(steps);
export default steps;